<template>
  <div>
    <b-embed
      type="iframe"
      :src="link"
      allowfullscreen
    />
  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue';

export default {
    components: {
        BEmbed,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        params: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        link() {
            const { params } = this;
            const query = Object.keys(params).map(key => `${key}=${params[key]}`);

            let url = `https://bi.viainvest.com.br/${this.url}?token=${sessionStorage.getItem('token')}`;

            if (query.length) {
                url = `${url}&${query.join('&')}`;
            }
            return url;
        },
    },
};
</script>
