<template>
  <div>
    <div
      v-if="achievement"
      class="pyro"
    >
      <div class="before" />
      <div class="after" />
    </div>
    <b-row class="px-2 justify-content-between">
      <span

        style="font-size: 22px; font-weight: 500; "
      > Próxima alteração de painel: {{ changeDashboardTimer | remaingTime }}</span>
    </b-row>

    <b-carousel
      v-if="render"
      v-model="slide"
      :interval="changeDashboardInterval"
      controls
      indicators
    >
      <BCarouselSlide
        v-for="(code, index) in codes"
        :key="index"
      >
        <template #img>
          <dash :url="code" />
        </template>
      </BCarouselSlide>
    </b-carousel>
  </div>
</template>

<script>
import {
    BCarousel, BCarouselSlide, BRow,
} from 'bootstrap-vue';

import Dash from '../components/Dash.vue';

const msToSeconds = ms => ms / 1000;
const minuteToSeconds = minute => minute * 60;

export default {
    components: {
        Dash,
        BCarousel,
        BCarouselSlide,
        BRow,
    },

    filters: {
        remaingTime(value) {
            return `${String(Math.floor(value / 60)).padStart(2, '0')}:${String(value % 60).padStart(2, '0')}`;
        },
    },

    data() {
        return {
            codes: [
                'goals-borderos',
                'goals-soulpay',
            ],
            time: 15,
            render: false,
            loopIsActive: true,
            timerRunning: false,
            intervalId: null,
            remaingTime: 0,
            counterId: null,
            achievement: false,
            changeDashboardInterval: 60 * 1000,
            changeDashboardTimer: null,
            slide: 0,
            optionsTime: [
                { value: 1, text: '1 Minuto' },
                { value: 5, text: '5 Minutos' },
                { value: 15, text: '15 Minutos' },
                { value: 30, text: '30 Minutos' },
                { value: 45, text: '45 Minutos' },
                { value: 60, text: '60 Minutos' },
            ],
        };
    },

    watch: {
        time() {
            this.executeLoop();
        },

        remaingTime() {
            if (this.remaingTime <= 0) {
                clearInterval(this.counterId);

                this.executeLoop();
            }
        },

        slide() {
            this.startCounter();
        },
    },

    async mounted() {
        this.executeLoop();
        this.startCounter();
    },

    methods: {
        async reRender(property) {
            this[property] = false;
            await new Promise(resolve => setTimeout(resolve, 3));
            this.changeDashboardTimer = this.changeDashboardInterval / 1000;
            this[property] = true;
        },

        async executeLoop() {
            this.reRender('render');
            this.doRemainTime();
            this.startCounter();
        },

        startCounter() {
            this.changeDashboardTimer = msToSeconds(this.changeDashboardInterval);

            if (this.counterId2) clearInterval(this.counterId2);

            this.counterId2 = setInterval(() => {
                this.changeDashboardTimer -= 1;

                // if (this.changeDashboardTimer === 0) this.achievement = true;

                if (!this.changeDashboardTimer) {
                    this.changeDashboardTimer = msToSeconds(this.changeDashboardInterval);
                    this.slide = this.slide === this.codes.length - 1 ? 0 : this.slide + 1;
                }
            }, 1000);
        },

        doRemainTime() {
            clearInterval(this.counterId);
            this.remainTime = 0;
            this.remaingTime = minuteToSeconds(this.time);

            this.counterId = setInterval(() => {
                this.remaingTime -= 1;
            }, 1000);
        },

        stopLoop() {
            this.loopIsActive = false;
        },
    },

};
</script>
